<template>

    <div class="page" >
      <div class="body" v-if="error_load">
        <div class="w-full error">
          Oooops! Something went wrong!
        </div>
      </div>
      <div v-if="route_data.route_info">
        <div class="roll_back_line flex justify-between">
          <div @click="goBack" class="inline-block flex w-1/3 justify-start items-center go_back">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 18L8 12L14 6" stroke="#6c757d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="h-full label_roll_back">
              Back
            </div>
          </div>
        </div>
        <div class="title_line flex items-center justify-between w-full px-16">
          <h1 class="title">Print Preview</h1>
          <div class="route-action flex gap-10p pointer" @click="goPrint">
            Print page
          </div>
        </div>
        <div id="printPage">
          <div class="logo py-10 m-auto w-full">
            <img class="m-auto" v-if="logo_url" :src="logo_url" alt="logo">
            <img class="m-auto" v-else src="@/assets/images/logo.png" alt="logo">
          </div>
          <div class="body px-16">

            <div class="directions py-6 w-full flex flex-col items-center justify-around gap-15">
              <div class="w-full map text-center m-auto flex justify-around">
                <HereMap
                    v-if='route_data.direction_data'
                    :routeData='route_data.direction_data'
                    :start_printing='start_printing'
                    :is_plan="false"
                    :map_width="'1000px'"
                    :map_height="'1000px'"
                    :zoom_default="6"
                />
              </div>
            </div>
            <!-- <div class="legend">
              <h2>Legend</h2>
              <div class="flex items-center justify-start gap-20">
                <div class="flex items-center justify-between gap-15 legend-item" v-for="(item, index) in route_data.direction_data.routes[0].sections" :key="index">
                  <div class="hidden">{{item}}</div>
                  <div class="flex justify-between items-center gap-10p" v-if="index + 1 != getMarkers.length">
                  <span class="legend_color" :class='colors[index % colors.length]'>
                  </span>
                    <span>
                    Route (leg #{{index + 1}})
                  </span>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="route_details py-5">
              <div class="text-left p-2">
                <div class="title rounded bg-grey-lighter py-5 px-5">
                  <h2 >Request details</h2>
                </div>
                <table class="border-table border-black mt-1 w-full">
                  <tbody class="w-full">
                  <tr>
                    <td class="border border-slate-300">
                      <strong>Company</strong>
                    </td>
                    <td class="border border-slate-300">{{user_data.company_name}}</td>
                    <td class="border border-slate-300">
                      <strong>Submission date</strong>
                    </td>
                    <td class="border border-slate-300">{{route_data.created_at | moment('DD-MM-YYYY')}}</td>
                  </tr>
                  <tr>
                    <td class="border border-slate-300">
                      <strong>Route ID</strong>
                    </td>
                    <td class="border border-slate-300">{{$route.params.id}}</td>
                    <td class="border border-slate-300">
                      <strong>Start time</strong>
                    </td>
                    <td class="border border-slate-300">{{getDateTime(route_data.direction_data.routes[0].sections[0].departure.time)}}</td>
                  </tr>
                  <tr>
                    <td class="border border-slate-300">
                      <strong>Route Name</strong>
                    </td>
                    <td class="border border-slate-300">{{route_data.name}}</td>
                    <td class="border border-slate-300">
                      <strong>End time</strong>
                    </td><td class="border border-slate-300">{{getDateTime(route_data.direction_data.routes[0].sections[route_data.direction_data.routes[0].sections.length -1].arrival.time)}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="route_details py-5">
              <div class="text-left p-2">
                <div class="title rounded bg-grey-lighter py-5 px-5">
                  <h2 >Route details</h2>
                </div>
                <table class="border-table border-black mt-1 w-full">
                  <tbody class="w-full">
                  <tr>
                    <td class="border border-slate-300">
                      <strong>
                        Route name:
                      </strong>
                    </td>
                    <td class="border border-slate-300">
                      <strong>
                        Zane
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-slate-300">
                      Origin
                    </td>
                    <td class="border border-slate-300">
                      {{route_data.direction_data.routes[0].sections[0].departure.place.postalCode}},
                      {{route_data.direction_data.routes[0].sections[0].departure.place.address}}
                    </td>
                  </tr>
                  <tr v-for="(location, index) in route_data.direction_data.routes[0].sections" :key="index">

                    <td class="border border-slate-300">
                      {{ index +1 }} Destination
                    </td>
                    <td class="border border-slate-300">{{ location.arrival.place.postalCode }}, {{ location.arrival.place.address }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="vehicle_details py-5">
              <div class="text-left p-2">
                <div class="title rounded bg-grey-lighter py-5 px-5">
                  <h2 >Vehicle details</h2>
                </div>
                <table class="border-table border-black mt-1 w-full">
                  <tbody class="w-full">
                  <tr>
                    <td class="border border-slate-300">
                      <strong>
                        Name or Internal ID
                      </strong>
                    </td>
                    <td class="border border-slate-300">
                      <strong>
                        Reg. Number
                      </strong>
                    </td>
                    <td class="border border-slate-300">
                      <strong>
                        Make
                      </strong>
                    </td>
                    <td class="border border-slate-300">
                      <strong>
                        Type
                      </strong>
                    </td>
                    <td class="border border-slate-300">
                      <strong>
                        Height (Metres)
                      </strong>
                    </td>
                    <td class="border border-slate-300">
                      <strong>
                        Width (Metres)
                      </strong>
                    </td>
                    <td class="border border-slate-300">
                      <strong>
                        Length (Metres)
                      </strong>
                    </td>
                    <td class="border border-slate-300">
                      <strong>
                        Weight (Tonnes)
                      </strong>
                    </td>
                  </tr>
                  <tr v-for="(veh, i) in route_data.vehicle_info" :key="i">
                    <td class="border border-slate-300">
                      {{veh.name || '-'}}
                    </td>
                    <td class="border border-slate-300">
                      {{veh.registration_number || '-'}}
                    </td>
                    <td class="border border-slate-300">
                      {{veh.vehicle_make || '-'}}
                    </td>
                    <td class="border border-slate-300">
                      {{ veh.vehicle_type ? parseTypeByKeys(veh.vehicle_type) : '-' }}
                    </td>
                    <td class="border border-slate-300">
                      {{veh.height || '-'}} m
                    </td>
                    <td class="border border-slate-300">
                      {{veh.width || '-'}} m
                    </td>
                    <td class="border border-slate-300">
                      {{veh.length || '-'}} m
                    </td>
                    <td class="border border-slate-300">
                      {{veh.weight || '-'}} t
                    </td>
                  </tr>
                  <tr v-if='route_data.route_options && route_data.route_options.vehicles_dimensions'>
                    <td class="border border-slate-300" colspan='4'>
                      Planned Dimension
                    </td>
                    <td class="border border-slate-300">
                      {{route_data.route_options.vehicles_dimensions.height.val || '-'}} m
                    </td>
                    <td class="border border-slate-300">
                      {{route_data.route_options.vehicles_dimensions.width.val || '-'}} m
                    </td>
                    <td class="border border-slate-300">
                      {{route_data.route_options.vehicles_dimensions.length.val || '-'}} m
                    </td>
                    <td class="border border-slate-300">
                      {{route_data.route_options.vehicles_dimensions.weight.val || '-'}} t
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class='reach_text' v-if='react_text'>
              <div class=' py-5' v-html='react_text'></div>
            </div>
            <div class="w-full direction py-5">
              <div class="inner way">
                <div class="flex-none p-4 border-b">
                  <div class="flex justify-between items-center">
                    <strong class="text-xl font-medium">
                      Directions
                    </strong>
                  </div>
                  <div class="justify-between font-medium">
                    <div class="block text-lg text-orange-dark">
                      {{ secondsToDhms() }}
                      <span class="text-lg text-grey-dark">{{ parsedDistance.m }} miles ({{ parsedDistance.k }} km)</span>
                    </div>

                  </div>
                </div>

              </div>
            </div>
            <RouteInstructions
                v-if="route_data.direction_data.routes[0].sections"
                :sections="route_data.direction_data.routes[0].sections"
            />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import moment from "moment";
import {createIconWithText, parseTypeByKeys} from "@/services/helpers";
import HereMap from "@/components/HereMap.vue";
import RouteInstructions from "@/components/plan-elements/RouteInstructions.vue";
import {baseErrHandler, getUserRoutesText} from "@/services/api";
export default {
  name: "RoutePrintV2",
  components: {
    RouteInstructions,
    HereMap,
  },
  data() {
    return {
      react_text: '',
      logo_url: null,
      start_printing: false,
      error_load: false,
      route_data: [],
      user_data: JSON.parse(localStorage.getItem('user')),
      colors: ['blue', 'green', 'red', 'yellow', 'purple', 'orange', 'cyan', 'magenta'],
      timeDuration: 0,
      distance: 0,
      parsedDistance: {
        k: null,
        m: null
      },
      waypoints: [],
      partDistance: [],
      zoom: 6,
      center: { lat: 53.495949, lng: -0.131529 },
      url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGF3c29uc2NvdHQiLCJhIjoiY2p4Ymp5YzhlMWU2eDNvbGdpM2o5endvYyJ9.dOMZ433p1ilQN22-njY91A",
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      traffic_main: null,
      add_vehicles: null,
      low_emission: null,
      ulez: null,
      london_control: null,
    }
  },
  computed: {
    ...mapGetters([
      'getMultiWaypointsText',
      'getColors',
      'getMarkers',
      'getMultiWaypoints'
    ]),
  },
  methods: {
    parseTypeByKeys,
    createIconWithText,
    ...mapMutations([
    ]),
    async goPrint() {
        this.start_printing = true;
        setTimeout(async () => {
          await this.$htmlToPaper('printPage');
        }, 1200);
    },
    logoUrl() {
      this.logo_url = `${this.$http.baseUrl()}/` + JSON.parse(localStorage.getItem('supportData')).logo_url || null
    },
    getDateTime(dtime) {
      const formattedDate = dtime.replace(/T(\d{2}:\d{2}):.*$/, ' $1');
      return moment(formattedDate).format('DD-MM-YYYY, HH:mm');
    },
    goBack() {
      this.$router.go(-1);
    },
    estimatedEndTime(dtime) {
      return moment(dtime).add(this.timeDuration, 'seconds').format('DD-MM-YYYY, HH:mm');
    },
    async getRoute() {
      try {
        const route = await this.$http.getAuth(`${this.$http.apiUrl()}/routes/${this.$route.params.id}`) || [];
        this.route_data = route.data;
        if (!this.route_data) {
          this.error_load = true;
          return;
        }
        await this.selectedPoints();
        this.parseDistance();
      } catch (e) {
        console.log(e)
      }
    },
    selectedPoints() {
      if (this.route_data.directions_data) {
        this.selected_points = this.route_data.direction_data.routes[0].sections.map((section) => {
          return {
            lat: section.departure.place.originalLocation.lat,
            lng: section.departure.place.originalLocation.lng
          }
        });
      }
    },
    secondsToDhms() {
      const seconds = this.route_data.direction_data.routes[0].sections.reduce((acc, section) => {
        return acc + section.travelSummary.duration;
      }, 0);
      var d = Math.floor(seconds / (3600*24));
      var h = Math.floor(seconds % (3600*24) / 3600);
      var m = Math.floor(seconds % 3600 / 60);

      var dDisplay = d > 0 ? d + (" d, ") : "";
      var hDisplay = h > 0 ? h + (" h, ") : "";
      var mDisplay = m > 0 ? m + ("min") : "";
      return dDisplay + hDisplay + mDisplay;
    },
    async parseDistance() {

      const travelLength = this.route_data.direction_data.routes[0].sections.reduce((acc, section) => {
        return acc + section.travelSummary.length;
      }, 0);
      this.parsedDistance.k = Math.round(travelLength / 1000);
      this.parsedDistance.m = Math.round((travelLength / 1000) / 1.609344);
    },
    distanceToString(meters) {
      if(meters)
        return `${Math.round(meters / 1000)} Km (${Math.round((meters / 1000) / 1.609344)} miles)`;
      else return ''
    },
    async getRoutesText() {
      if (this.error_load) return;
      await baseErrHandler(async () => {
        const user = await getUserRoutesText();

        this.react_text = user?.data?.user?.routes_info;
      });
    },
  },
  async mounted() {
    await this.getRoute();
    await this.logoUrl();
    await this.getRoutesText();
  }
}
</script>

<style lang="scss" scoped>

.direction {
  .inner {

  }
}
.legend-item {
  &:nth-child(1) {
    .legend_color {
      background: blue;
    }
  }
  &:nth-child(2) {
    .legend_color {
      background: green;
    }
  }
  &:nth-child(3) {
    .legend_color {
      background: red;
    }
  }
  &:nth-child(4) {
    .legend_color {
      background: yellow;
    }
  }
  &:nth-child(5) {
    .legend_color {
      background: blue;
    }
  }
  &:nth-child(6) {
    .legend_color {
      background: red;
    }
  }
  &:nth-child(7) {
    .legend_color {
      background: green;
    }
  }
  &:nth-child(8) {
    .legend_color {
      background: yellow;
    }
  }
  &:nth-child(9) {
    .legend_color {
      background: blue;
    }
  }
  &:nth-child(10) {
    .legend_color {
      background: red;
    }
  }
}
.legend_color {
  width: 90px;
  height: 8px;
  border-radius: 5px;

}
</style>
